@import "variables";
@import "template_fonts";
@import "template";

.pagination {
  font-size: 0;
  line-height: 0;
  margin-bottom: -10px;
  margin-left: -10px;
  justify-content: center;

  & > .page-item, & > li {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-weight: 400;

    a {
      &,
      &:hover,
      &:focus {
        color: #fff;
        background-color: #35ad79;
        border-color: #35ad79;
      }
    }

    &.active > span {
      color: #fff;
      background-color: #35ad79;
      border-color: #35ad79;
    }

    & > .page-link, & > a, & > span {
      display: inline-block;
      min-width: 42px;
      padding: 9px 9px;
      border: 0 solid #d7d7d7;
      font-family: "Kanit", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      font-size: 18px;
      line-height: 1.33333;
      background-color: #fff;
      color: #151515;
      transition: all 0.3s ease-in-out;
    }

  }
}

h4.post-future-title {
  min-height: 120px;
}

.rd-navbar-classic .rd-navbar-list-link {
  max-width: 90px;
}

.swiper-slide-caption {
  margin-right: 60px;

  h1, a {
    font-weight: 900;
    text-shadow: 1px 1px 3px black, 0 0 1em black;
  }

  h4 {
    font-weight: 700;
    text-shadow: 1px 1px 3px black, 0 0 1em black;
  }
}

.list-marked {
  &.list-marked-categories {
    li {
      &.active {
        font-weight: 700;
      }
    }
  }
}

.product-content {
  min-height: 170px;
}

.product-list-img {
  width: 100%;
  height: 100%;
}

@media (min-width: 576px) {
  .contact-list dl {
    padding-left: 15px;
    padding-right: 40px;
  }
}

.word-break-all {
  word-break: break-all;
}

.promo-classic-meta {
  background-image: url(../img/next-event.jpg);
}

.button-media-vk {
  background: #4A76A8;

  &:hover {
    background: #6986ae;
  }

  .button-media-icon {
    left: -40px;
  }
}

.button-media-youtube {
  background: #f06868;

  &:hover {
    background: #fd9393;
  }

  .button-media-icon {
    left: -15px;
  }
}

.search-button-aside {
  position: relative;

  a {
    position: absolute;
    right: 0;
    top: 50%;
  }
}

.disable-transform {

}

.player-info-modern:hover {
  .player-info-modern-title {
    opacity: 0;
    transform: translateY(-120px);

    &.disable-transform {
      opacity: 1;
      transform: none;
    }
  }
}

.product-share {
  .ya-share2__item {
    margin: auto !important;
    margin-top: 13px !important;
    display: block !important;
  }
}

.agteam-tabs {
  .tabs-custom {
    .nav-link {
      height: 40px;
    }
  }

  .tabs-corporate-boxed .nav-tabs {
    display: flex;
  }

  @media (min-width: 1200px) {
    .card-standing-game-status, .card-standing-team {
      width: 80%;
    }
  }

  @media (min-width: 576px) {
    .card-standing-game-status, .card-standing-team {
      width: 75%;
    }
  }

  .card-standing-position {
    min-width: 70px;
  }

  .card-standing-game-status, .card-standing-team {
    width: 77%;
  }

  .card-standing-team {
    .card-standing-team-figure {
      img {
        width: 60px;
        height: 60px;
        max-width: 60px;
      }
    }

    .card-standing-team-country {
      margin-top: 5px;
    }
  }

  @media (min-width: 768px) {
    .card-standing-team-item {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .card-standing-counter {
    padding: 7px 3px 20px;

    @media (max-width: 767px) {
      padding: 20px 3px 20px;
    }

    @media (max-width: 767px) {
      padding: 35px 3px 20px;
    }
  }
}

.font-style-normal {
  font-style: normal !important;
}

.widget-competition {
  .list-group-item {
    cursor: pointer;
    height: 70px;
    margin-bottom: 5px;
    background-repeat: no-repeat;
    background-size: cover;

    &.competition-rskg {
      background-image: url(../img/rskg.jpg);
    }

    &.competition-rc {
      background-image: url(../img/rc.jpg);
    }

    &.competition-zt {
      background-image: url(../img/zt.jpg);
    }

    &:hover {
      filter: brightness(80%);
    }

    .title {
      height: 40px;
      color: #fff;
      font-weight: 600;
      font-size: 1.5em;
      width: 160px;
      text-align: left;
      line-height: 2;
      border-radius: .25rem;
    }
  }

  .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .badge {
    width: 40px;
    height: 40px;
  }

  i {
    font-size: 2em;
    padding-top: 10px;
    padding-left: 3px;
  }

}

.select-w-100 {
  min-width: 100px;
}

.select-w-150 {
  min-width: 150px;
}

.track-images {
  img {
    object-fit: cover;
  }
}

.track-description {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 20px 0;
  }
}

.text-black {
  color: black;
}

.item-inline {
  display: inline;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.post-future-footer {
  &.post-share {
    .post-detail {
      z-index: 1;
    }

    @media (max-width: 1200px) {
      .inline-toggle-parent {
        width: 190px !important;

        &.active {
          border-bottom: 1px solid #e1e1e1;
        }
      }
    }
  }
}

.error-text-color {
  color: #35ad79;
}

.card-standing-diff {
  width: 17%;
}

.ui-to-top {
  left: 30px;
}

.grecaptcha-badge {
  z-index: 101;
}

@media (min-width: 576px) {
  .grecaptcha-badge {
    bottom: 40px !important;
  }
}

.static-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px auto;
  width: 900px;
}

.static-body h1, .static-body h2 {
  text-align: center;
}

.static-body_text {
  margin: 30px auto 10px auto;
  flex-wrap: wrap;
}

.static-body_text, .static-body_text p {
  display: flex;
  color: black;
}

.post-future-title {
  a {
    white-space: initial !important;
  }
}